import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

import PromptService from '~services/prompt';
import { useLayout } from '~hooks/useLayout';
import IntakeActions from '~redux/intake';

import { getListingRedirect } from '~helpers/common';

import { UserContext } from '~contexts/user';

import AuthenticationSkeleton from '../../skeleton';
import Form from '../form';
import Header from '../partials/header';
import IncorrectStatus from '../partials/incorrect-status';
import { getPictureFromProvider, getCurrentIdentityProvider } from './helpers';

import '../style.scss';
import '../../style.scss';

// Page for finalizing registration with external federated identity providers (FB/Google)
const FinalizeExternal = () => {
  useLayout({ displayCities: false });

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { user: authUser, isAuthenticated, isLoading } = useAuth0();
  const {
    createCustomer,
    createWorker,
    user: backendUser,
    isLoading: backendUserLoading,
    getOwnData,
    uploadUserPicture,
  } = useContext(UserContext);

  const personId = !authUser ? null : authUser['https://sr2.ca/claims/user_metadata'].uuid;
  const identityProvider = !authUser ? null : getCurrentIdentityProvider(authUser.sub);
  const { wish, operations, referrer, accountType } = useSelector((state) => state.intake);

  const [picture, setPicture] = useState(null);
  const [isLoadingPicture, setIsLoadingPicture] = useState(true);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const STATUS =
    (isLoading && isAuthenticated && backendUserLoading) || isProcessing
      ? 'Loading'
      : isAuthenticated && !backendUserLoading && !backendUser.personId
      ? 'ExternalFinalize'
      : isAuthenticated && !backendUserLoading && backendUser.personId
      ? 'AlreadyFinalized'
      : 'IncorrectStatus';

  useEffect(async () => {
    if (STATUS === 'ExternalFinalize' && isLoadingPicture) {
      try {
        const pic = await getPictureFromProvider(authUser?.picture, identityProvider);
        setPicture(pic);
      } catch (err) {
        setPicture(null);
      } finally {
        setIsLoadingPicture(false);
      }
    }
    if (STATUS === 'AlreadyFinalized' || STATUS === 'IncorrectStatus') {
      setIsLoadingPicture(false);
    }
  }, [STATUS]);

  const registerErrorPrompt = () => {
    PromptService.alert({
      title: intl.formatMessage({
        id: 'containers.authentication.finalize.server-error-prompt.title',
        defaultMessage: 'Server error',
      }),
      content: intl.formatMessage({
        id: 'containers.authentication.finalize.server-error-prompt.content',
        defaultMessage: 'We could not register you at this time. Please try again later.',
      }),
    }).then(() => window.location.replace(`/finalize/external`));
  };

  const clearIntake = () => {
    dispatch(IntakeActions.setWish(null));
    dispatch(IntakeActions.setOperations(null));
    dispatch(IntakeActions.setAccountType(null));
  };

  const handleSubmit = async ({ email, firstName, picture, role }) => {
    if (isRegistering) {
      return;
    }
    setIsRegistering(true);

    try {
      const locationId =
        role === 'client'
          ? wish?.address.id || wish?.address.place_id
          : operations?.address?.id || operations?.address?.place_id;
      const userPicture = picture ? JSON.stringify(await uploadUserPicture(picture, personId)) : '';

      if (role === 'client') {
        await createCustomer({
          personId,
          email,
          firstName,
          profilePicture: userPicture,
          emailVerified: authUser.email_verified,
          phoneVerified: false,
          wish,
        });
      } else if (role === 'worker') {
        await createWorker({
          personId,
          email,
          firstName,
          profilePicture: userPicture,
          emailVerified: authUser.email_verified,
          phoneVerified: false,
          operations,
        });
      }

      setIsProcessing(true);
      await getOwnData(personId);

      if (role === 'client' && !wish) {
        history.push('/intake/client');
        return;
      }

      if (role === 'worker' && !operations) {
        history.push('/intake/worker');
        return;
      }

      if (referrer) {
        history.push(referrer);
        clearIntake();
        return;
      }

      const redirectAddress = getListingRedirect(role, locationId);

      history.push(redirectAddress);
      clearIntake();
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
        registerErrorPrompt();
        setIsRegistering(false);
        setIsProcessing(false);
      }
    }
  };

  return (
    <section className="c-authentication l-container">
      <div className="c-authentication__content">
        {STATUS === 'Loading' || isLoadingPicture ? (
          <AuthenticationSkeleton />
        ) : STATUS === 'AlreadyFinalized' ? (
          <Redirect
            to={{
              pathname: `/account`,
            }}
          />
        ) : STATUS === 'ExternalFinalize' ? (
          <div>
            <div className="l-mb2">
              <Header />
            </div>
            <Form
              // eslint-disable-next-line camelcase
              firstName={authUser?.given_name || ''}
              email={authUser?.email || ''}
              picture={picture}
              role={accountType || ''}
              disableEmail={true}
              onSubmit={handleSubmit}
            />
          </div>
        ) : (
          <IncorrectStatus />
        )}
      </div>
    </section>
  );
};

export default FinalizeExternal;
