import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { setLocale } from 'yup';

import { ValidationSchemaProvider } from './validationSchemaContext';

import './style.scss';

const ERROR_AUTO_SCROLL_MARGIN = 40;

setLocale({
  mixed: {
    required: 'components.form.validation.mixed.required',
  },
  string: {
    email: 'components.form.validation.string.email',
    min: 'components.form.validation.string.min',
    max: 'components.form.validation.string.max',
    phoneNumber: 'components.form.validation.string.phone-number',
  },
  boolean: {
    checked: 'components.form.validation.boolean.checked',
  },
  date: {
    required: 'components.form.validation.date.required',
  },
});

export default function Form({ className, children, validationSchema, methods, fieldsOrder, onSubmit }) {
  const findAllByKey = (obj, keyToFind) => {
    return Object.entries(obj).reduce(
      (acc, [key, value]) =>
        key === keyToFind ? acc.concat(value) : value && typeof value === 'object' ? acc.concat(findAllByKey(value, keyToFind)) : acc,
      []
    );
  };

  const findRefInField = (field) => {
    const refs = findAllByKey(field, 'ref');
    const refsFiltered = refs.filter(Boolean);
    return refsFiltered.length > 0 ? refsFiltered[0] : null;
  };

  const scrollToError = (field) => {
    const ref = findRefInField(field);
    if (!ref) {
      return;
    }
    ref.focus();
    // window.scrollBy(0, -ERROR_AUTO_SCROLL_MARGIN);
  };

  useEffect(() => {
    // Scroll to first error
    if (!methods.formState.isSubmitted) {
      return;
    }
    if (Object.keys(methods.errors).length > 0) {
      if (fieldsOrder) {
        fieldsOrder.some((field) => {
          return Object.keys(methods.errors).some((key) => {
            if (key === field) {
              scrollToError(methods.errors[key]);
              return true;
            }
            return false;
          });
        });
      } else {
        scrollToError(methods.errors[Object.keys(methods.errors)[0]]);
      }
    }
  }, [methods.errors]);

  return (
    <ValidationSchemaProvider value={validationSchema}>
      <FormProvider {...methods}>
        <form method="post" className={className} onSubmit={methods.handleSubmit(onSubmit)}>
          {children}
        </form>
      </FormProvider>
    </ValidationSchemaProvider>
  );
}
