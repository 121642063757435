import React from 'react';

import { Slider, Handle, Track, Rail, Handles, Tracks } from '~components/slider';

const ZoomSlider = ({ min = 1, step = 0.1, max = 5, disabled, value, className, onChange = () => {} }) => {
  const handleChange = (v) => {
    onChange(v[0]);
  };

  const domain = [min, max];

  return (
    <Slider mode={1} step={step} domain={domain} className={className} onUpdate={handleChange} values={value} disabled={disabled}>
      <Rail>{({ getRailProps }) => <div className="c-slider__rail-outer" {...getRailProps()} />}</Rail>
      <Handles>
        {({ handles, activeHandleID, getHandleProps }) => (
          <div>
            {handles.map((handle) => (
              <div key={handle.id}>
                <Handle handle={handle} domain={domain} getHandleProps={getHandleProps} isActive={handle.id === activeHandleID} />
              </div>
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  );
};

export default ZoomSlider;
