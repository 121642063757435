import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { AuthContext } from '~contexts/auth';

import Button from '~components/button';

const IncorrectStatus = () => {
  const intl = useIntl();
  const { signUp } = useContext(AuthContext);

  return (
    <div>
      <h3 className="l-headline--tertiary l-mb1 l-error">
        {intl.formatMessage({
          id: 'containers.authentication.finalize.incorrect-status.headline',
          defaultMessage: 'Incorrect status',
        })}
      </h3>
      <Button onClick={signUp}>
        {intl.formatMessage({
          id: 'containers.authentication.finalize.incorrect-status.button',
          defaultMessage: 'Go to register',
        })}
      </Button>
    </div>
  );
};

export default IncorrectStatus;
