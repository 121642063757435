import React from 'react';

import { SkeletonSimple } from '~components/skeleton';

import './style.scss';

const AuthenticationSkeleton = () => {
  return (
    <div className="c-skeleton-simple__authentication">
      <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__authentication-header" />
      <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__authentication-intro" />
      <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__authentication-item" />
      <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__authentication-item" />
      <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__authentication-button" />
    </div>
  );
};

export default AuthenticationSkeleton;
