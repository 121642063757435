import React from 'react';
import { useIntl } from 'react-intl';

const Header = () => {
  const intl = useIntl();

  return (
    <>
      <h2 className="l-headline--secondary">
        {intl.formatMessage({
          id: 'containers.authentication.finalize.headline',
          defaultMessage: 'Finalize your profile',
        })}
      </h2>
      <div className="c-authentication__intro">
        {intl.formatMessage({
          id: 'containers.authentication.finalize.intro',
          defaultMessage: 'Tell us something more about yourself',
        })}
      </div>
    </>
  );
};

export default Header;
