export const getPictureFromProvider = async (pictureUrl, identityProvider) => {
  try {
    if (identityProvider === 'facebook') {
      //@TODO get bigger pic from facebook
      //https://auth0.com/docs/authenticate/identity-providers/calling-an-external-idp-api
      return pictureUrl;
    }
    if (identityProvider === 'google') {
      return pictureUrl;
    }
  } catch (err) {
    return null;
  }
};

export const getCurrentIdentityProvider = (provider) => {
  if(!provider) return null;
  if(provider.indexOf('facebook') >= 0) {
    return 'facebook'
  }

  if(provider.indexOf('google') >= 0) {
    return 'google';
  }

  return null;
};

export const getCurrentIdentityProviderUserId = (provider) => {
  if(!provider) return null;
  return provider.substr(provider.indexOf('|') + 1);
};
