import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { FiUser, FiMail, FiUpload } from 'react-icons/fi';
import { useIntl } from 'react-intl';

import Form from '~components/forms/form';

import { InputControlled } from '~components/forms/input';

import PhotoUpload from '~components/photo-upload';
import Button from '~components/button';

const FinalizeForm = ({ firstName = '', email = '', picture, role = '', showEmail = true, disableEmail = false, onSubmit = () => {} }) => {
  const intl = useIntl();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [image, setImage] = useState(picture);
  const [isUploaded, setIsUploaded] = useState(false);

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      firstName,
      email,
      role,
    },
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().max(64).noEmailInContent().noPhoneInContent(),
    email: Yup.string().email().required().max(256),
    role: Yup.string().required(),
  });

  const handleFormSubmit = async (data) => {
    if (isEmpty(methods.errors) && !isSubmitting) {
      setIsSubmitting(true);
      data.picture = image;
      onSubmit(data);
    }
  };

  const handleImageChange = (img) => {
    setImage(img);
    setIsUploaded(true);
  };

  return (
    <Form
      className="c-authentication__form c-finalize__form"
      methods={methods}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {/* PHOTO */}
      <div className="c-fieldset">
        <PhotoUpload.Wrapper
          className="c-photo-upload--vertical c-photo-upload--finalize"
          onLoad={handleImageChange}
          onChange={handleImageChange}
          isUploaded={isUploaded}
          defaultImage={!image ? null : `${image}${image?.updatedAt ? `?${image.updatedAt}` : ''}`}
          updatedAt={image?.updatedAt}
          onStartSelect={() => setIsUploaded(false)}
        >
          <PhotoUpload.Preview className="c-finalize__photo" />
          <PhotoUpload.Content>
            <div className="c-finalize__photo-buttons">
              <PhotoUpload.Button className="c-btn--text" disabled={false}>
                <FiUpload />
                <span>
                  {intl.formatMessage({
                    id: 'containers.authentication.finalize.form.fields.photo.update-photo',
                    defaultMessage: 'Update photo',
                  })}
                </span>
              </PhotoUpload.Button>
            </div>
          </PhotoUpload.Content>
        </PhotoUpload.Wrapper>
      </div>

      {/* FIRSTNAME */}
      <div className="c-fieldset">
        <div className="c-fieldset__label">
          {intl.formatMessage({
            id: 'containers.authentication.finalize.form.fields.firstname.label',
            defaultMessage: 'First name',
          })}
        </div>
        <div className="c-fieldset__values">
          <InputControlled
            type="text"
            autoComplete="on"
            name="firstName"
            placeholder={intl.formatMessage({
              id: 'containers.authentication.finalize.form.fields.firstname.placeholder',
              defaultMessage: 'John',
            })}
            icon={<FiUser />}
          />
        </div>
      </div>

      {/* EMAIL */}
      {showEmail && (
        <div className="c-fieldset">
          <div className="c-fieldset__label">
            {intl.formatMessage({
              id: 'containers.authentication.finalize.form.fields.email.label',
              defaultMessage: 'E-mail',
            })}
          </div>
          <div className="c-fieldset__values">
            <InputControlled
              type="email"
              autoComplete="on"
              name="email"
              placeholder={intl.formatMessage({
                id: 'containers.authentication.finalize.form.fields.email.placeholder',
                defaultMessage: 'E-mail',
              })}
              icon={<FiMail />}
              disabled={disableEmail}
            />
          </div>
        </div>
      )}

      {/* SUBMIT */}
      <div className="c-form__buttons">
        {!role ? (
          <>
            <Button
              disabled={!isEmpty(methods.errors) || isSubmitting}
              isLoading={isSubmitting}
              onClick={() => methods.setValue('role', 'client')}
              type="submit"
              name='client'
              {...methods.register('role')} 
            >
              {intl.formatMessage({
                id: 'containers.authentication.finalize.form.looking-for-cleaner',
                defaultMessage: `I'm looking for a cleaner`,
              })}
            </Button>
            <Button
              type="submit"
              disabled={!isEmpty(methods.errors) || isSubmitting}
              isLoading={isSubmitting}
              onClick={() => methods.setValue('role', 'worker')}
              name='worker'
              {...methods.register('role')} 
            >
              {intl.formatMessage({
                id: 'containers.authentication.finalize.form.looking-for-client',
                defaultMessage: `I'm looking for a client`,
              })}
            </Button>
          </>
        ) : (
          <Button
            name={role}
            type="submit"
            disabled={!isEmpty(methods.errors) || isSubmitting}
            isLoading={isSubmitting}
            {...methods.register('role')} 
          >
            {intl.formatMessage({
              id: 'containers.authentication.finalize.form.submit',
              defaultMessage: 'Continue',
            })}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default FinalizeForm;
