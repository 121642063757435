import React from 'react';
import {
  Slider as RCSlider,
  Rail,
  Handles,
  Tracks,
  Ticks,
} from 'react-compound-slider';
import cx from 'classnames';

import './style.scss';

export const Slider = ({ children, className, ...props }) => {
  return (
    <div
      className={cx({
        [className]: !!className,
      })}
    >
      <div className="c-slider__container">
        <div className="c-slider__wrapper">
          <RCSlider className="c-slider" {...props}>
            {children}
          </RCSlider>
        </div>
      </div>
    </div>
  );
};

export function Track({
  source,
  target,
  getTrackProps,
  disabled,
  reversed,
  children,
}) {
  return (
    <div
      className={cx('c-slider__track', {
        'c-slider__track--disabled': disabled,
      })}
      style={{
        left: !reversed ? `${source.percent}%` : `${target.percent}%`,
        width: !reversed
          ? `${target.percent - source.percent}%`
          : `${100 - target.percent}%`,
      }}
      {...getTrackProps()}
    >
      {children}
    </div>
  );
}

export const Handle = ({ domain, handle, getHandleProps, children }) => (
  <div
    role="slider"
    aria-valuemin={domain.min}
    aria-valuemax={domain.max}
    aria-valuenow={handle.value}
    className="c-slider__handle"
    style={{
      left: `${handle.percent}%`,
    }}
    {...getHandleProps(handle.id)}
  >
    {children}
  </div>
);

export function Tick({ tick, count, format = (d) => d }) {
  return (
    <div>
      <div
        className="c-slider__tick-mark"
        style={{
          left: `${tick.percent}%`,
        }}
      />
      <div
        className="c-slider__tick-value"
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
}

export { Rail, Handles, Tracks, Ticks };
