import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import AvatarEditor from 'react-avatar-editor';
import { FiRotateCcw, FiRotateCw } from 'react-icons/fi';

import { useStateFromProp } from '~hooks/common';

import Button from '~components/button';
import Modal from '~components/modal';
import ZoomSlider from './zoom-slider';

import './style.scss';

const PhotoEdit = ({ image, isOpened, isUploading, onToggle = () => {}, onChange = () => {} }) => {
  const intl = useIntl();
  const editorRef = useRef();

  const [isModalOpened, setModalOpen] = useStateFromProp(isOpened, onToggle);
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);

  const handleRotate = (direction) => {
    const newRotate = direction === 1 ? (rotate === 270 ? 0 : rotate + 90) : rotate === 0 ? 270 : rotate - 90;
    setRotate(newRotate);
  };

  const handleSave = () => {
    const newImage = editorRef.current.getImageScaledToCanvas().toDataURL();
    onChange(newImage);
  };

  return (
    <Modal.Wrapper isOpened={isModalOpened} onClose={() => setModalOpen()} className="c-modal--photo-edit">
      <Modal.Header>
        <h2 className="l-headline--secondary">
          {intl.formatMessage({
            id: 'components.photo-edit.headline',
            defaultMessage: 'Update profile picture',
          })}
        </h2>
      </Modal.Header>
      <Modal.Content>
        <div className="c-photo-edit">
          <AvatarEditor
            ref={editorRef}
            image={image}
            width={200}
            height={200}
            border={0}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={zoom}
            rotate={rotate}
            borderRadius={200}
          />
        </div>
        <div className="c-photo-edit__actions">
          <button className="c-photo-edit__rotate" type="button" onClick={() => handleRotate(-1)} disabled={isUploading}>
            <FiRotateCcw />
          </button>
          <button className="c-photo-edit__rotate" type="button" onClick={() => handleRotate(1)} disabled={isUploading}>
            <FiRotateCw />
          </button>

          <ZoomSlider value={[1]} className="c-photo-edit__slider" onChange={setZoom} disabled={isUploading} />
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="c-modal__buttons">
          <button type="button" className="c-btn--text" disabled={isUploading} onClick={() => onToggle(false)}>
            {intl.formatMessage({
              id: 'components.photo-edit.cancel',
              defaultMessage: 'Cancel',
            })}
          </button>
          <Button className="c-btn" onClick={handleSave} disabled={isUploading} isLoading={isUploading}>
            {intl.formatMessage({
              id: 'components.photo-edit.save',
              defaultMessage: 'Save',
            })}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Wrapper>
  );
};

export default PhotoEdit;
