import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useIntl } from 'react-intl';
import { useAuth0 } from "@auth0/auth0-react";

import PromptService from '~services/prompt';
import { useLayout } from '~hooks/useLayout';
import IntakeActions from '~redux/intake';

import { getListingRedirect } from '~helpers/common';

import { UserContext } from '~contexts/user';

import Form from '../form';
import Header from '../partials/header';
import AlreadyFinalized from '../partials/already-finalized';
import IncorrectStatus from '../partials/incorrect-status';
import AuthenticationSkeleton from '../../skeleton';

import '../style.scss';
import '../../style.scss';

// Page for finalizing registration with email
const Finalize = () => {
  useLayout({ displayCities: false });

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { user: authUser, isAuthenticated } = useAuth0();
  const { createCustomer, createWorker, user: backendUser, isLoading: backendUserLoading, getOwnData, uploadUserPicture } = useContext(UserContext);
  const { wish, operations, accountType, referrer } = useSelector((state) => state.intake);

  const STATUS =
    isAuthenticated && backendUserLoading
      ? 'Loading'
      : isAuthenticated && !backendUserLoading && backendUser?.personId
      ? 'AlreadyFinalized'
      : 'EmailFinalize';

  const [isRegistering, setIsRegistering] = useState(false);

  const registerErrorPrompt = () => {
    PromptService.alert({
      title: intl.formatMessage({
        id: 'containers.authentication.finalize.server-error-prompt.title',
        defaultMessage: 'Server error',
      }),
      content: intl.formatMessage({
        id: 'containers.authentication.finalize.server-error-prompt.content',
        defaultMessage: 'We could not register you at this time. Please try again later.',
      }),
    }).then(() => window.location.replace(`/finalize/email`));
  };

  const clearIntake = () => {
    dispatch(IntakeActions.setWish(null));
    dispatch(IntakeActions.setOperations(null));
    dispatch(IntakeActions.setAccountType(null));
  };

  const handleSubmit = async ({ firstName, picture, role }) => {
    if (isRegistering) {
      return;
    }
    setIsRegistering(true);

    try {
      const personId = authUser['https://sr2.ca/claims/user_metadata'].uuid;
      const locationId = role === 'client' ? wish?.address?.place_id : operations?.address?.place_id;

      const userPicture = picture ? JSON.stringify(await uploadUserPicture(picture, personId)) : '';

      if (role === 'client') {
        await createCustomer({
          personId,
          email: authUser.email,
          firstName,
          profilePicture: userPicture,
          emailVerified: authUser.email_verified,
          phoneVerified: false,
          wish,
        });
      } else if (role === 'worker') {
        await createWorker({
          personId,
          email: authUser.email,
          firstName,
          profilePicture: userPicture,
          emailVerified: authUser.email_verified,
          phoneVerified: false,
          operations,
        });
      }

      await getOwnData(personId);

      if (role === 'client' && !wish) {
        history.push('/intake/client');
        return;
      }

      if (role === 'worker' && !operations) {
        history.push('/intake/worker');
        return;
      }

      if (referrer) {
        history.push(referrer);
        clearIntake();
        return;
      }

      const redirectAddress = getListingRedirect(role, locationId);

      history.push(redirectAddress);
      clearIntake();
    } catch (err) {
      console.log(err);
      registerErrorPrompt();
      setIsRegistering(false);
    }
  };

  return (
    <section className="c-authentication l-container">
      <div className="c-authentication__content">
        {STATUS === 'Loading' ? (
          <AuthenticationSkeleton />
        ) : STATUS === 'AlreadyFinalized' ? (
          <AlreadyFinalized />
        ) : STATUS === 'EmailFinalize' ? (
          <div>
            <div className="l-mb2">
              <Header />
            </div>
            <Form showEmail={false} role={accountType} onSubmit={handleSubmit} />
          </div>
        ) : (
          <IncorrectStatus />
        )}
      </div>
    </section>
  );
};

export default Finalize;
