import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

import { getChildrenByType } from '~helpers/children';

import PhotoEdit from '~components/photo-edit';

import { loadImgFromUrl, imgToDataURL } from '~helpers/images';

import './style.scss';

const Context = React.createContext();

const MB = 1048576;

const Content = ({ children, className }) => {
  const { error } = React.useContext(Context);
  return (
    <div
      className={cx('c-photo-upload__content', {
        [className]: !!className,
      })}
    >
      {children}
      {error && <div className="c-input__error">{error}</div>}
    </div>
  );
};

const Button = ({ children, disabled, className }) => {
  const intl = useIntl();
  const { handleFileSelect, image, clearImage } = React.useContext(Context);
  return (
    <>
      <button
        type="button"
        className={cx('c-photo-upload__button', {
          [className]: !!className,
        })}
        disabled={disabled}
        onClick={handleFileSelect}
      >
        {children}
      </button>
      {image && (
        <button type="button" className="c-photo-upload__remove c-btn-text" disabled={disabled} onClick={clearImage}>
          {intl.formatMessage({ id: 'components.photo-upload.delete', defaultMessage: 'Delete photo' })}
        </button>
      )}
    </>
  );
};

const Preview = ({ children, className }) => {
  const { image, updatedAt } = React.useContext(Context);

  return (
    <div
      className={cx('c-photo-upload__preview', {
        [className]: !!className,
      })}
    >
      <img
        src={isEmpty(image) ? '/images/camera.svg' : image}
        key={updatedAt}
        alt=""
        className={cx({
          'c-photo-upload__placeholder': isEmpty(image),
        })}
        crossOrigin="anonymous"
      />
      {children}
    </div>
  );
};

const Wrapper = ({
  children,
  className,
  maxFileSize = 5,
  defaultImage,
  isUploading,
  isUploaded,
  updatedAt,
  onStartSelect = () => {},
  onChange = () => {},
  onLoad = () => {},
}) => {
  const intl = useIntl();

  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [error, setError] = useState(null);

  useEffect(async () => {
    if (image || !defaultImage) {
      return;
    }

    try {
      const img = await loadImgFromUrl(defaultImage);
      const imgDataURL = imgToDataURL(img);

      setImage(imgDataURL);
      onLoad(imgDataURL);
    } catch (err) {
      console.log('Error while loading image');
      setImage(null);
    }
  }, [defaultImage]);

  useEffect(() => {
    if (newImage) {
      setShowEditModal(true);
    }
  }, [newImage]);

  useEffect(() => {
    if (isUploaded) {
      setShowEditModal(false);
    }
  }, [isUploaded]);

  const handleFileSelect = (ev) => {
    ev.preventDefault();
    inputRef.current.click();
    onStartSelect();
  };

  const handleChange = (ev) => {
    ev.preventDefault();

    const reader = new FileReader();
    const lFile = ev.target.files[0];

    if (!lFile) {
      return;
    }

    if (lFile.size > maxFileSize * MB) {
      setError(
        intl.formatMessage(
          { id: 'components.photo-upload.file-too-big', defaultMessage: 'File size must be less than {maxFileSize}MB' },
          { maxFileSize }
        )
      );
      return;
    }
    setError(null);

    reader.onloadend = () => {
      // setImage(reader.result);
      setNewImage(reader.result);
    };

    reader.readAsDataURL(lFile);
  };

  const clearImage = () => {
    setImage(null);
    setNewImage(null);
    setError(null);
    onChange(null);
  };

  const handleImageEdited = (img) => {
    setImage(img);
    onChange(img);
  };

  const cancelEditModal = () => {
    setShowEditModal(false);
    setNewImage(null);
  };

  const preview = getChildrenByType(children, 2, Preview);
  const content = getChildrenByType(children, 2, Content);
  const button = getChildrenByType(children, 0, Button);

  return (
    <Context.Provider
      value={{
        handleFileSelect,
        image,
        clearImage,
        error,
        isUploaded,
        updatedAt,
      }}
    >
      <div
        className={cx('c-photo-upload', {
          [className]: !!className,
        })}
      >
        {preview}
        <input
          type="file"
          accept="image/png,image/jpeg,image/jpg"
          ref={inputRef}
          className="c-photo-upload__input"
          onChange={handleChange}
        />
        {content}
        {button ? <div className="c-photo-upload__buttons">{button}</div> : null}
      </div>
      {showEditModal && (
        <PhotoEdit
          isOpened={showEditModal}
          image={newImage}
          onToggle={cancelEditModal}
          onChange={(img) => handleImageEdited(img)}
          isUploading={isUploading}
        />
      )}
    </Context.Provider>
  );
};

export default { Wrapper, Preview, Content, Button };
