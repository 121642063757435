import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Button from '~components/button';

const AlreadyFinalized = () => {
  const intl = useIntl();

  return (
    <div>
      <h2 className="l-headline--secondary l-mb1">
        {intl.formatMessage({
          id: 'containers.authentication.finalize.already-finalized.headline',
          defaultMessage: 'Already finalized',
        })}
      </h2>
      <Link to="/account">
        <Button>
          {intl.formatMessage({
            id: 'containers.authentication.finalize.already-finalized.button',
            defaultMessage: 'Go to dashboard',
          })}
        </Button>
      </Link>
    </div>
  );
};

export default AlreadyFinalized;
